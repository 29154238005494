// @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Outfit:wght@100..900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Faculty+Glyphic&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

@import './colorVariables.scss';

// @font-face {
// 	font-family: 'Satoshi Variable';
// 	src: url('../public/fonts/Satoshi-Variable.ttf') format('truetype-variations');
// 	font-weight: 100, 200, 300, 400, 500, 600, 700, 800, 900; /* Adjust as needed */
// 	font-style: normal;
// }

@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url('../public/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype-variations');
	font-weight: 100, 200, 300, 400, 500, 600, 700, 800, 900;
	font-style: normal;
}

@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url('../public/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf') format('truetype-variations');
	font-weight: 100, 200, 300, 400, 500, 600, 700, 800, 900;
	font-style: italic;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	// font-family: 'Satoshi Variable', sans-serif !important;
	font-family: 'Plus Jakarta Sans', sans-serif !important;
	// font-family: 'Quicksand', sans-serif !important;
}

.font-other {
	font-family: 'Faculty Glyphic', sans-serif !important;
}

.font-code {
	font-family: 'SF Mono', 'Monaco', 'Inconsolata', 'Fira Mono', 'Droid Sans Mono', 'Source Code Pro', monospace !important;
}

html {
	scroll-behavior: smooth;
}

body {
	background-color: $white;
	scroll-behavior: smooth;
	margin: 0 auto;
	color: #5f6c7b;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
	font-weight: 600;
	font-size: 2rem;
}
h2 {
	font-size: 1.75rem;
}
h3 {
	font-size: 1.5rem;
}
p {
	font-size: 1rem;
}

h1,
h2,
h3,
hr {
	color: $dark-charcoal;
}

.css-k008qs {
	display: -webkit-box !important;
}
