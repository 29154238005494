@import '../../colorVariables.scss';

.messagingContainer {
	display: inline-block;
	width: 100%;
	max-width: 100%;
	padding: 10px;

	.rightSide {
		max-width: 600px;
		margin-left: 1px;
		background-color: $white;
		max-height: calc(var(--doc-height) - 20px);
	}
	.leftSide {
		max-width: 330px;
		background-color: $white;
		.cardDesign {
			max-height: 100dvh !important;
			overflow-y: auto;

			.users-list {
				// make the height of the list dynamic
				height: calc(100% - 60px);
				overflow-y: auto;
			}
		}
		.search-group {
			background-color: $background-grey;
			border-radius: 5px;
			padding: 10px;
			input {
				background-color: $background-grey;

				flex-grow: 1;
				&:focus {
					border: none;
					outline: none;
				}
				&::placeholder {
					color: $greyless;
				}
			}
			.search-icon {
				color: $greyless;
			}
		}

		.user-item {
			border-radius: 5px;
			padding: 12px;
			transition: 0.3s;
			background-color: $background-greyless;
			.heading-name {
				color: $charcoal;
			}
			&:hover {
				cursor: pointer;
				background-color: $background-grey;
			}
		}
		.selected {
			background-color: $background-grey;
			transition: 0.3s;
			font-weight: 500;
			box-shadow: none;
		}
	}
}
.sendIcon {
	font-size: 36px;
	vertical-align: middle;
	cursor: pointer;
	background-color: $link-blue;
	border-radius: 5px;
	padding: 6px;
	color: #fffffe;
	margin-left: 8px;
	&:hover {
		box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
	}
}

.textarea-container {
	position: relative;
	width: 100%;
	.message-textarea {
		padding: 10px;
		padding-right: 40px; /* Adjust this value based on the size of your send button */
		// min-height: 80px;
	}

	.send-icon {
		position: absolute;
		right: 10px; /* Adjust as needed */
		bottom: 10px;
		// transform: translateY(-50%);
		cursor: pointer;
		color: $link-blue;
		&:hover {
			color: $link-blue;
		}
	}
	// .font-icon {
	// 	font-size: 22px; /* Adjust the size of the icon as needed */
	// }
}

.header {
	margin-top: 15px;
	padding: 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-end;
	gap: 8px;
	box-shadow: none;

	.innerHeader {
		margin: 0;
		display: flex;
		padding: 10px;
		width: fit-content;
		border-radius: 5px;
		border: 1px solid $background-grey;
	}
}

.profile-image {
	margin: 0;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	object-fit: cover;
}

.mainContent {
	.textContent {
		padding: 0 15px;
		word-wrap: break-word;
		margin-bottom: 0;
		margin-top: 0;
		font-size: 0.9em;
		color: $charcoal;
	}
	.time {
		font-size: 0.7em;
		margin: 0;
		padding-top: 5px;
		color: $grey;
	}
}
.currentUserSender {
	display: flex;
	flex-direction: row-reverse;
	text-align: right;
	gap: 5px;
	align-items: flex-end;
	.currentUserSenderHeader {
		border-radius: 10px;
		background-color: $background-grey;
	}
}

.sendIcon {
	font-size: 36px;
	vertical-align: middle;
	cursor: pointer;
	background-color: $link-blue;
	border-radius: 5px;
	padding: 6px;
	color: #fffffe;
	&:hover {
		box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
	}
}

// media queries
@media (max-width: 900px) {
	.messagingContainer {
		.rightSide {
			max-width: 100%;
		}
		.leftSide {
			width: 100%;
			max-width: 100%;
			height: var(--doc-height);
		}
	}
}
